import React from "react";
import OwlCarousel from "react-owl-carousel";
import VissionMission from "./VisionMission";

const AboutUs = () => {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      900:{
        items:2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <div>
      <div>
        <img
          className="aboutimg"
          src="banner-2.jpg"
          alt=""
          style={{ width: "100%", height: "320px" }}
        />
      </div>
      <div className="container">
        <div className="row">
          <div
            className="col-md-6 text-center about1"
            style={{ marginTop: "60px", padding: "27px" }}
          >
            <h6 style={{ color: "#010161", fontFamily: "Carnas Light" }}>
              HOW WE ARE DIFFERENT
            </h6>
            <br />
            <h3 style={{ fontFamily: "Carnas Light", fontSize: "2.9rem" }}>
              About Us
            </h3>
            <br />
            <p
              style={{
                color: "#585f63",
                fontFamily: "inherit",
                // marginTop: "35px",
                lineHeight: "25px",
                fontSize: "17px",
              }}
            >
              Lead HR Consulting is a specialised Human Resources firm rendering
              HR services to the needy clients with a Human Touch and our client
              list ranging from Manufacturing, E-Commerce, Automobiles, Banking,
              Service Industries, Retail, FMCG, Engineering, Pharmaceuticals,
              Medical Devices and new Start Ups.
            </p>
            <p
              style={{
                color: "#585f63",
                fontFamily: "inherit",
                // marginTop: "35px",
                lineHeight: "25px",
                fontSize: "17px",
              }}
            >
              Two passionate and dynamic HR professionals have started this HR
              firm after working for more than 10 years in HR function.
            </p>
          </div>
          <div className="col-md-6 about2" style={{ padding: "70px" }}>
            <img src="aboutimage2.jpg" alt="" style={{ width: "100%" }} />
          </div>
        </div>
      </div>
      {/* <div
        className="container"
        style={{ backgroundColor: "#C8D6E5", padding: "50px" }}
      >
        <div className="row text-center">
          <div className="col-md-12">
            <h6>HELPING YOU PUT SYSTEMS IN PLACE THAT WORK</h6>
            <br />
            <h3>About Our Team</h3>
            <br />
            <img
              src="teammeeting.webp"
              alt=""
              style={{ width: "80%", height: "300px" }}
            />
            <br />
            <h6 className="pt-3 text-align-justify">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse. Lorem ipsum dolor sit amet,
              consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate
              velit esse.
            </h6>
          </div>
        </div>
      </div> */}
      <div className="container">
        <div className="row mt-5">
          <div
            className="col-md-7 mt-3 about"
            style={{
              marginLeft: "150px",
              padding: "71px",
              backgroundColor: "#010161",
              position: "relative",
            }}
          >
            <div className="row">
              <div className="col-md-6 ">
                <h6 style={{ color: "white", fontFamily: "Carnas Light" }}>
                  WHY
                </h6>
                <h4
                  style={{
                    color: "white",
                    fontSize: "25px",
                    fontWeight: "200",
                    fontFamily: "Carnas Light"
                  }}
                >
                  CHOOSE US
                </h4>
                <br />
                <p
                  style={{
                    color: "white",
                    fontFamily: "Carnas Light",
                    // marginTop: "35px",
                    lineHeight: "25px",
                    fontSize: "20px",
                    textAlign: "justify",
                    fontWeight: "0",
                  }}
                >
                  We work and deliver keeping specific customer requirements in
                  mind and we own the entire responsibility in delivering the
                  agreed services by taking calculated risks to collaborate in
                  the success of our esteemed clients.
                </p>
                <br />
              </div>
              <div className="col-md-5 text-end">
                <img
                  className="aboutimg"
                  src="whychooseus.webp"
                  alt=""
                  style={{
                    width: "86%",
                    height: "400px",
                    position: "absolute",
                    top: "1.5rem",
                    left: "450px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <VissionMission />
      </div>
      <div
        className="container-fluid"
        style={{ backgroundColor: "#F1F6FB", marginTop: "4rem" }}
      >
        <div className="row text-center about3" style={{ padding: "90px" }}>
          <h6 style={{ color: "#010161", fontFamily: "Carnas Light" }}>
            HELPING YOU PUT SYSTEMS IN PLACE THAT WORK
          </h6>
          <br />
          <h3 style={{ color: "#010161", fontFamily: "Carnas Light" }}>
            OUR EXPERTISE AREAS
          </h3>
          <br />
          <br />
          <br />
          <OwlCarousel className="owl-theme" loop margin={10} nav {...options}>
            <div
              className="col-md-12 about4"
              style={{
                backgroundColor: "#000077",
                width: "100%",
                height: "365px",
              }}
            >
              <h3 style={{ color: "#ED1B1A", fontFamily: "Carnas Light" }}>
                Talent Acquisition and Retention
              </h3>
              <br />
              <p
                style={{
                  color: "white",
                  fontFamily: "inherit",
                  // marginTop: "35px",
                  lineHeight: "25px",
                  fontSize: "17px",
                  textAlign: "justify",
                }}
              >
                {" "}
                Talent acquisition is the process of identifying, attracting,
                recruiting, and retaining top talent. Retention refers to the
                strategies and initiatives undertaken by HR to keep valuable
                employees engaged, satisfied, and committed to the organization.
              </p>
            </div>
            <div
              className="col-md-12 about4"
              style={{
                backgroundColor: "#000077",
                width: "100%",
                height: "365px",
              }}
            >
              <h3 style={{ color: "#ED1B1A", fontFamily: "Carnas Light" }}>
                Learning and Development
              </h3>
              <br />
              <p
                style={{
                  color: "white",
                  fontFamily: "inherit",
                  // marginTop: "35px",
                  lineHeight: "25px",
                  fontSize: "17px",
                  textAlign: "justify",
                }}
              >
                {" "}
                Learning and development, also known as L&D, is a continuous
                process of encouraging the professional development of your
                employees. It involves analyzing skills gaps in your business
                and designing training programs that empower employees with
                specific knowledge and skills that drive increased performance.
              </p>
            </div>
            <div
              className="col-md-12 about4"
              style={{
                backgroundColor: "#000077",
                width: "100%",
                height: "365px",
              }}
            >
              <h3 style={{ color: "#ED1B1A", fontFamily: "Carnas Light" }}>
                Industrial Relations
              </h3>
              <br />
              <p
                style={{
                  color: "white",
                  fontFamily: "inherit",
                  // marginTop: "35px",
                  lineHeight: "25px",
                  fontSize: "17px",
                  textAlign: "justify",
                }}
              >
                {" "}
                Disciplinary Actions, Strike, Union Agreements, Right Shop Floor
                Culture.Industrial Relations is a term used to define the
                relationship between workers and employers, and it also deals
                with the relationship between labor and management.
              </p>
            </div>
            <div
              className="col-md-12 about4"
              style={{
                backgroundColor: "#000077",
                width: "100%",
                height: "365px",
              }}
            >
              <h3 style={{ color: "#ED1B1A", fontFamily: "Carnas Light" }}>
                Statutory Compliance
              </h3>
              <br />
              <p
                style={{
                  color: "white",
                  fontFamily: "inherit",
                  // marginTop: "35px",
                  lineHeight: "25px",
                  fontSize: "17px",
                  textAlign: "justify",
                }}
              >
                {" "}
                Compliance means adherence. Thus, Statutory Compliance means
                adhering to rules and regulations. Statutory Compliance in HR
                refers to the legal framework that an organization should adhere
                to in dealing with its employees.
              </p>
            </div>
            <div
              className="col-md-12 about4"
              style={{
                backgroundColor: "#000077",
                width: "100%",
                height: "365px",
              }}
            >
              <h3 style={{ color: "#ED1B1A", fontFamily: "Carnas Light" }}>
                Contractual Labour Management
              </h3>
              <br />
              <p
                style={{
                  color: "white",
                  fontFamily: "inherit",
                  // marginTop: "35px",
                  lineHeight: "25px",
                  fontSize: "17px",
                  textAlign: "justify",
                }}
              >
                {" "}
                Contract labour management system is a web based system for
                employers to have a better check and control over their
                contractors and the labour under them. With our contract labour
                management system, organizations are able to implement security
                norms that help create a safe & secure work environment.
              </p>
            </div>
            <div
              className="col-md-12 about4"
              style={{
                backgroundColor: "#000077",
                width: "100%",
                height: "365px",
              }}
            >
              <h3 style={{ color: "#ED1B1A", fontFamily: "Carnas Light" }}>
                Performance Management
              </h3>
              <br />
              <p
                style={{
                  color: "white",
                  fontFamily: "inherit",
                  // marginTop: "35px",
                  lineHeight: "25px",
                  fontSize: "17px",
                  textAlign: "justify",
                }}
              >
                {" "}
                Performance management is defined as the process of continuous
                communication and feedback between a manager and employee
                towards the achievement of organizational objectives.
              </p>
            </div>
            <div
              className="col-md-12 about4"
              style={{
                backgroundColor: "#000077",
                width: "100%",
                height: "365px",
              }}
            >
              <h3 style={{ color: "#ED1B1A", fontFamily: "Carnas Light" }}>
                Liasoning
              </h3>
              <br />
              <p
                style={{
                  color: "white",
                  fontFamily: "inherit",
                  // marginTop: "35px",
                  lineHeight: "25px",
                  fontSize: "17px",
                  textAlign: "justify",
                }}
              >
                {" "}
                HR Liaisons are responsible for one or more programs and/or
                online systems to perform a variety of functions including
                processing employee time off/leave requests, timekeeping,
                benefits enrollment, position descriptions, recruitment, hiring,
                onboarding, new hire orientations, training, performance
                management, workers.
              </p>
            </div>
            {/* <div className="col-md-12 containerrrr">
              <br />
              <img
                src="team1.jpg"
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
              <div className="caption">
                <h1>Gwen Stacy</h1>
                <p>Manager</p>
              </div>
            </div>
            <div className="col-md-12 containerrrr">
              <br />
              <img
                src="team1.jpg"
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
              <div className="caption">
                <h1>Gwen Stacy</h1>
                <p>Manager</p>
              </div>
            </div>
            <div className="col-md-12 containerrrr">
              <br />
              <img
                src="team1.jpg"
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
              <div className="caption">
                <h1>Gwen Stacy</h1>
                <p>Manager</p>
              </div>
            </div>
            <div className="col-md-12 containerrrr">
              <br />
              <img
                src="team1.jpg"
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
              <div className="caption">
                <h1>Gwen Stacy</h1>
                <p>Manager</p>
              </div>
            </div> */}
          </OwlCarousel>
        </div>
      </div>
      {/* <div className="container">
        <div className="row mt-3">
          <div
            className="col-md-3 text-center carduu"
            style={{ border: "2px solid #C8D6E5", padding: "30px" }}
          >
            <GiReceiveMoney style={{ fontSize: "50px", color: "#010161" }} />
            <br />
            <h5>COMPENSATION CONSULTING</h5>
          </div>
        </div>
      </div> */}

      {/* <div className="container pt-5 ">
        <div className="row">
          <div className="col-md-11 text-end">
            <img
              src="homeimage4.jpg"
              alt=""
              style={{
                width: "100%",
                height: "550px",
                position: "absolute",
                top: "1764px",
                left: "0px",
              }}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AboutUs;
