import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { GiReceiveMoney } from "react-icons/gi";
import { FaPeopleGroup } from "react-icons/fa6";
import { SiMicrosoftpowerpoint } from "react-icons/si";
import { FaWpforms } from "react-icons/fa6";
import { MdMenuBook } from "react-icons/md";
import { AiOutlineAudit } from "react-icons/ai";
import { TbCirclesRelation } from "react-icons/tb";
import { PiQuotesFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";

const Home = () => {
  const Navigate = useNavigate();
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <div>
      <div style={{ position: "relative" }}>
        <div className="container-fluid topone">
          <div className="row" style={{ padding: "unset" }}>
            <div
              className="col-md-4"
              style={{
                backgroundColor: "#F8F9FA",
                height: "35rem",
                width: "24.1%",
                paddingLeft: "unset",
                paddingRight: "unset",
              }}
            ></div>
            <div
              className="col-md-8"
              style={{
                backgroundImage: "linear-gradient(to right, #010161 , #00008e)",
                height: "35rem",
                width: " 75%",
                paddingLeft: "unset",
                paddingRight: "unset",
              }}
            ></div>
          </div>
        </div>

        <div
          className="container home"
          style={{ position: "absolute", top: "25px", left: "50px" }}
        >
          <div className="row">
            <div className="col-md-12 hom" style={{ marginLeft: "45px" }}>
              <Carousel fade>
                <Carousel.Item>
                  <img
                    className="image"
                    src="homeimage2.jpg"
                    alt="pic"
                    style={{
                      width: "100%",
                      height: "500px",
                      position: "relative",
                    }}
                  />
                  <Carousel.Caption
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    style={{
                      position: "absolute",
                      top: "25%",
                      left: "10%",
                      textAlign: "start",
                    }}
                  >
                    <h6
                      style={{
                        color: "#010161",
                        fontSize: "18px",
                        fontFamily: "Carnas Light",
                        fontWeight: "500",
                      }}
                    >
                      YOUR STRATEGIC
                    </h6>
                    <h3
                      style={{
                        color: "black",
                        fontSize: "47px",
                        fontFamily: "Carnas Light",
                        fontWeight: "400",
                      }}
                    >
                      HUMAN RESOURCE
                      <br /> SOLUTION PARTNER
                    </h3>
                    <button
                      className="btn btn-primary buttonnnn"
                      type="submit"
                      onClick={() => Navigate("/aboutus")}
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #010161 , #00008e)",
                        borderRadius: "0px",
                        color: "white",
                        border: "2px solid #010161",
                        padding: "15px",
                      }}
                    >
                      {" "}
                      MORE INFO
                    </button>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="image"
                    src="homeimage1.jpg"
                    alt="pic"
                    style={{
                      width: "100%",
                      height: "500px",
                      position: "relative",
                    }}
                  />
                  <Carousel.Caption
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    className="carousal"
                    style={{
                      position: "absolute",
                      top: "25%",
                      left: "10%",
                      textAlign: "start",
                    }}
                  >
                    <h6
                      style={{
                        color: "#010161",
                        fontSize: "18px",
                        fontFamily: "Carnas Light",
                        fontWeight: "500",
                      }}
                    >
                      EXPERT KNOWLEDGE
                    </h6>
                    <h3
                      style={{
                        color: "black",
                        fontSize: "47px",
                        fontFamily: "Carnas Light",
                        fontWeight: "400",
                      }}
                    >
                      TRANSFORMING THE <br />
                      WORK PLACE
                    </h3>
                    <button
                      className="btn btn-primary buttonnnn"
                      type="submit"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #010161 , #00008e)",
                        borderRadius: "0px",
                        color: "white",
                        border: "2px solid #010161",
                        padding: "15px",
                      }}
                      onClick={() => Navigate("/services")}
                    >
                      {" "}
                      MORE INFO
                    </button>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="image"
                    src="homeimage3.png"
                    alt="pic"
                    style={{
                      width: "100%",
                      height: "500px",
                      position: "relative",
                    }}
                  />
                  <Carousel.Caption
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    style={{
                      position: "absolute",
                      top: "25%",
                      left: "10%",
                      textAlign: "start",
                    }}
                  >
                    <h6
                      style={{
                        color: "#010161",
                        fontSize: "18px",
                        fontFamily: "Carnas Light",
                        fontWeight: "500",
                      }}
                    >
                      YOUR STRATEGIC
                    </h6>
                    <h3
                      style={{
                        color: "black",
                        fontSize: "47px",
                        fontFamily: "Carnas Light",
                        fontWeight: "400",
                      }}
                    >
                      HUMAN RESOURCE <br />
                      SOLUTION PARTNER
                    </h3>
                    <button
                      className="btn btn-primary buttonnnn"
                      type="submit"
                      onClick={() => Navigate("/contactus")}
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #010161 , #00008e)",
                        borderRadius: "0px",
                        color: "white",
                        border: "2px solid #010161",
                        padding: "15px",
                      }}
                    >
                      {" "}
                      MORE INFO
                    </button>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container  homee"
        style={{ backgroundColor: "#F1F6FB", padding: "50px" }}
      >
        <div className="row ">
          <div className="col-md-6  home1">
            <h5 style={{ color: "#010161", fontFamily: "Carnas Light" }}>
              HOW WE ARE DIFFERENT
            </h5>
            <br />
            <h3 style={{ fontFamily: "Carnas Light", fontSize: "2.9rem" }}>
              Helping to create engaging, productive and profitable
              organizations
            </h3>
          </div>
          <div className="col-md-6 p-4  home2">
            <p
              style={{
                color: "#585f63",
                fontFamily: "inherit",
                marginTop: "84px",
                lineHeight: "25px",
                fontSize: "17px",
              }}
            >
              HR Advisors is a recruitment company that provides a comprehensive
              service that identifies the role requirements in the organization
              and designs the job description and skill requirements for that
              role. <br />
              <br />
              HR Advisors provides a broad menu of Human Resource Outsourcing
              Services for companies. From an economical complete service.
              <a
                href="/aboutus"
                style={{ float: "right", marginRight: "28px" }}
              >
                View more
              </a>
            </p>
          </div>
        </div>
      </div>

      <div style={{ position: "relative" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-11 text-end home3">
              <img
                src="homeimage3.png"
                alt=""
                style={{ width: "73%", height: "459px" }}
              />
            </div>
          </div>
          {/* </div>
 
        <div className="container home4" > */}
          <div className="row ">
            <div
              className="col-md-4 collll"
              style={{
                backgroundImage: "linear-gradient(to right, #010161 , #00008e)",
                padding: "72px",
                position: "absolute",
                top: "225px",
                left: "222px",
              }}
            >
              <h3
                style={{
                  color: "white",
                  fontSize: "1.5rem",
                  fontWeight: "250",
                  textAlign: "justify",
                  fontFamily: "Carnas Light",
                }}
              >
                With our specialists, and experts at your disposal, you will
                face complex HR issues with confidence
              </h3>
              <hr
                style={{
                  color: "white",
                  border: "2px solid white",
                  width: "60%",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid " style={{ paddingTop: "5rem" }}>
        <div className="row">
          <div
            className="col-md-12 "
            style={{ backgroundColor: "#1E3799", padding: "30px" }}
          >
            <h3 style={{ color: "white" }}>
              <span style={{ fontFamily: "Carnas Light" }}>ABOUT</span>{" "}
              <span style={{ color: "#FD5E00", fontFamily: "Carnas Light" }}>
                FOUNDER
              </span>
            </h3>
            <hr
              style={{
                color: "white",
                border: "2px solid white",
                width: "60%",
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ backgroundColor: "#1E3799" }}>
            <div className="row ">
              <div className="col-md-4 ps-5">
                <img
                  src="founder1.png"
                  alt=""
                  style={{
                    width: "80%",
                    height: "85%",
                    boxShadow: "10px 10px 32px 0 rgba(19,19,20,0.37)",
                  }}
                />
              </div>
              <div className="col-md-8" style={{ padding: "40px" }}>
                <h6
                  style={{
                    lineHeight: "35px",
                    color: "white",
                    fontSize: "18px",
                    fontWeight: "100",
                    textAlign: "justify",
                    fontFamily: "inherit",
                  }}
                >
                  Shivaram has done his Bachelor’s Degree in Humanities and
                  Masters in Human Resources Management from Mysore University.
                  After his Post Graduation in Human Resources, he started
                  working for known Business Corporations such as Johnson
                  Controls, Ashirvad Pipes and VeeravahanaUdyog Limited as a HR
                  Generalist and has contributed immensely to the businesses he
                  was associating with. His collaboration with shop floor teams
                  has helped to win over union challenges and in the process, he
                  has earned his credibility through his dedicated work with
                  empathy. He has learnt the most practical way of handling HR
                  challenges and understood what works well in HR. After 10
                  years of expertise in Human Resources field, has made him to
                  dream big and started{" "}
                  <span style={{ fontWeight: "500", color: "#FD5E00" }}>
                    Lead HR Consulting
                  </span>{" "}
                  firm to cater HR Services and Solutions in a more effective
                  way to the businesses.
                </h6>
                <div
                  className="col-md-12 text-end"
                  style={{ paddingRight: "20px" }}
                >
                  <h5
                    style={{
                      color: "white",
                      fontStyle: "italic",
                      fontSize: "20px",
                    }}
                  >
                    -Shivaram
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container  whole"
        style={{ backgroundColor: "#F1F6FB", padding: "50px" }}
      >
        <div className="row ">
          <div className="col-md-6 p-3">
            <h5 style={{ color: "#010161", fontFamily: "Carnas Light" }}>
              CORE SERVICES
            </h5>
            <br />
            <h3 style={{ fontFamily: "Carnas Light", fontSize: "2.9rem" }}>
              We deliver HR outsorcing services through an integrated technology
            </h3>
          </div>
          <div className="col-md-6 p-3">
            <p
              style={{
                color: "#585f63",
                fontFamily: "inherit",
                marginTop: "45px",
                lineHeight: "25px",
                fontSize: "17px",
              }}
            >
              We offer comprehensive services such as Executive Staffing,
              Contractual/Lease manpower supply, Statutory Compliance, Payroll
              management , Learning and Development, Statutory Audits and
              Liasoning.
              <br />
              <br />
              With Our Company as your strategic HR partner, you can focus on
              such as Learning and Development and also Executive Staffing.
              <a
                href="/services"
                style={{ float: "right", marginRight: "99px" }}
              >
                View more
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="container  mb-5">
        <div className="row mt-3 mb-5 gap-5 hoome">
          <div
            className="col-2 col-sm-2 col-md-2 col-lg-2 text-center carduu "
            style={{
              border: "2px solid #C8D6E5",
              padding: "30px",
              height: "219px",
              backgroundColor: "#C8D6E5",
              marginLeft: "7px",
            }}
            onClick={() => Navigate("/services")}
          >
            <div>
              <FaPeopleGroup style={{ fontSize: "50px" }} />
              <br />
              <br />
              <h5>Executive Staffing</h5>
            </div>
          </div>
          <div
            className="col-2 col-sm-2 col-md-2 col-lg-2 text-center carduu"
            style={{
              border: "2px solid #C8D6E5",
              padding: "30px",
              height: "219px",
            }}
            onClick={() => Navigate("/services")}
          >
            <div>
              <SiMicrosoftpowerpoint style={{ fontSize: "50px" }} />
              <br />
              <br />
              <h5>
                Contractual/
                <br />
                Lease manpower supply
              </h5>
            </div>
          </div>
          <div
            className="col-2 col-sm-2 col-md-2 col-lg-2 text-center carduu"
            style={{
              border: "2px solid #C8D6E5",
              padding: "30px",
              height: "219px",
            }}
            onClick={() => Navigate("/services")}
          >
            <div>
              <FaWpforms style={{ fontSize: "50px" }} />
              <br />
              <br />
              <h5>Statutory Compliance</h5>
            </div>
          </div>
          <div
            className="col-2 col-sm-2 col-md-2 col-lg-2 text-center carduu"
            style={{
              border: "2px solid #C8D6E5",
              padding: "30px",
              height: "219px",
            }}
            onClick={() => Navigate("/services")}
          >
            <div>
              <GiReceiveMoney style={{ fontSize: "50px" }} />
              <br />
              <br />
              <h5>Payroll management</h5>
            </div>
          </div>
          <div
            className="col-2 col-sm-2 col-md-2 col-lg-2 text-center carduu"
            style={{
              border: "2px solid #C8D6E5",
              padding: "30px",
              height: "219px",
            }}
            onClick={() => Navigate("/services")}
          >
            <div>
              <MdMenuBook style={{ fontSize: "50px" }} />
              <br />
              <br />
              <h5>Learning and Development</h5>
            </div>
          </div>
          <div
            className="col-2 col-sm-2 col-md-2 col-lg-2 text-center carduu "
            style={{
              border: "2px solid #C8D6E5",
              padding: "30px",
              height: "219px",
              backgroundColor: "#C8D6E5",
              marginLeft: "7px",
            }}
            onClick={() => Navigate("/services")}
          >
            <div>
              <AiOutlineAudit style={{ fontSize: "50px" }} />
              <br />
              <br />
              <h5>Statutory Audits</h5>
            </div>
          </div>
          <div
            className="col-2 col-sm-2 col-md-2 col-lg-2 text-center carduu "
            style={{
              border: "2px solid #C8D6E5",
              padding: "30px",
              height: "219px",
              backgroundColor: "#C8D6E5",
            }}
            onClick={() => Navigate("/services")}
          >
            <div>
              <TbCirclesRelation style={{ fontSize: "50px" }} />
              <br />
              <br />
              <h5>Liasoning</h5>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container  mb-5">
        <div className="row mt-3 mb-5 gap-5"> */}

      {/* </div>
      </div> */}

      {/* <div className="container pt-5 ">
        <div className="row">
          <div className="col-md-11 text-end">
            <img
              src="homeimage4.jpg"
              alt=""
              style={{
                width: "100%",
                height: "550px",
                position: "absolute",
                top: "1764px",
                left: "0px",
              }}
            />
          </div>
        </div>
      </div> */}
      <div style={{ position: "relative" }}>
        <div className="container-fluid">
          <div className="row mt-5">
            <div
              className="col-md-7 mt-3 home9"
              style={{
                marginLeft: "150px",
                padding: "100px",
                backgroundColor: "#F1F6FB",
                position: "relative",
              }}
            >
              <div className="row">
                <OwlCarousel
                  className="owl-theme"
                  loop
                  margin={10}
                  nav
                  {...options}
                >
                  <div className="col-md-5 ">
                    <PiQuotesFill
                      style={{ fontSize: "50px", color: "#010161" }}
                    />
                    <br />
                    <h4
                      className="ps-5"
                      style={{
                        fontSize: "1.7rem",
                        fontFamily: "Carnas Light",
                        fontWeight: "400",
                      }}
                    >
                      These Guys Are Real Professionals.These Experts Have
                      Helped Us So Many Times !
                    </h4>
                    <br />
                    <h6 className="ps-5">
                      <span style={{ color: "#010161" }}>Damon Salvatore</span>
                      &nbsp;&nbsp;
                      <span style={{ color: "#A7ADB3", fontSize: "12px" }}>
                        -Manager Ltd
                      </span>
                    </h6>
                  </div>
                  <div className="col-md-5 ">
                    <PiQuotesFill
                      style={{ fontSize: "50px", color: "#010161" }}
                    />
                    <br />
                    <h4
                      className="ps-5"
                      style={{
                        fontSize: "1.7rem",
                        fontFamily: "Carnas Light",
                        fontWeight: "400",
                      }}
                    >
                      Thank you for picking the most professional employees for
                      our big company !
                    </h4>
                    <br />
                    <h6 className="ps-5">
                      <span style={{ color: "#010161" }}>Fred Peterson</span>
                      &nbsp;&nbsp;
                      <span style={{ color: "#A7ADB3", fontSize: "12px" }}>
                        -CEO Expert Ltd
                      </span>
                    </h6>
                  </div>
                </OwlCarousel>

                <div className="col-md-5 text-end home10">
                  <img
                    src="homeimage4.jpg"
                    alt=""
                    style={{
                      width: "84%",
                      // height: "550px",
                      position: "absolute",
                      top: "-20px",
                      left: "481px",
                      zIndex: "1",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
