import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from "./Components/NavBar";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import Services from "./Components/Services";
import ContactUs from "./Components/ContactUs";
import AboutUs from "./Components/AboutUs";
import Partners from "./Components/Partners";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Backtotop from "./Components/Backtotop";
import Whatsapp from "./Components/Whatsapp";
import PhoneCall from "./Components/PhoneCall";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <NavBar />
              <Home />
              <Partners />
              <Backtotop />
              <Whatsapp />
              <PhoneCall />
              <Footer />
            </>
          }
        />
         <Route
          path="/aboutus"
          element={
            <>
              <NavBar />
              <AboutUs />
              <Partners />
              <Backtotop />
              <Whatsapp />
              <PhoneCall />
              <Footer />
            </>
          }
        />
         <Route
          path="/services"
          element={
            <>
              <NavBar />
              <Services />
              <Partners />
              <Backtotop />
              <Whatsapp />
              <PhoneCall />
              <Footer />
            </>
          }
        />
         <Route
          path="/contactus"
          element={
            <>
              <NavBar />
              <ContactUs />
              <Partners />
              <Backtotop />
              <Whatsapp />
              <PhoneCall />
              <Footer />
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
