import React, { useRef } from "react";
import { SlSocialInstagram } from "react-icons/sl";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { TiSocialTwitter } from "react-icons/ti";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { AiFillLinkedin } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { GrMail } from "react-icons/gr";
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdLocationPin } from "react-icons/md";
import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { PiInstagramLogoFill } from "react-icons/pi";
import { IoLogoLinkedin } from "react-icons/io";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import emailjs from "@emailjs/browser";

function Footer() {
  const navigate = useNavigate();

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_hxhi9je",
        "template_xax9v3v",
        form.current,
        "MUIWT1wj9Eqv9fJC0"
      )
      .then(
        (result) => {
          // console.log(result.text, "afewaeh");
          window.location.assign("/contactus");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <div className="wefre ">
        <div
          className="udhciwe"
          style={{
            backgroundColor: "#010161",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="container p-5">
            <div className="ejfie pb-4" style={{ paddingTop: "70px" }}>
              <div className="row">
                <div className="col-md-3">
                  <h5
                    className="text-uppercase mb-4 font-weight-bold"
                    style={{ color: "#FE5E00", fontFamily: "Carnas Light" }}
                  >
                    Contact information
                  </h5>
                  <hr
                    style={{
                      border: "1px solid white",
                      color: "white",
                      width: "80%",
                    }}
                  />
                  {/* <img
                    src="logo.jpg"
                    alt=""
                    style={{
                      width: "30%",
                      height: "7rem",
                      borderRadius: "50%",
                    }}
                  />
                  <br /> */}
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <MdLocationPin style={{ fontSize: "23px" }} />
                      </span>
                      <span style={{ fontFamily: "inherit" }}>
                        No.151, 2nd Floor, 6th A Main Road, 7th A” Cross, Near
                        Hoysala Circle, Kengeri Satellite Town, Bengaluru -
                        560060
                      </span>
                    </p>
                  </p>
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-3">
                      <span>
                        <GrMail />
                      </span>
                      <span style={{ fontFamily: "inherit" }}>
                        {" "}
                        leadhrconsultingsolutions@gmail.com
                      </span>
                    </p>
                  </p>
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-3">
                      <span>
                        <BiSolidPhoneCall />
                      </span>
                      <span style={{ fontFamily: "inherit" }}>
                        +91-9743403928, 7259646275
                      </span>
                    </p>
                  </p>
                </div>
                <div className="col-md-2">
                  <h5
                    className="text-uppercase mb-4 font-weight-bold"
                    style={{ color: "#FE5E00", fontFamily: "Carnas Light" }}
                  >
                    QUICK LINKS
                  </h5>
                  <hr
                    style={{
                      border: "1px solid white",
                      color: "white",
                      width: "70%",
                    }}
                  />
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <MdKeyboardDoubleArrowRight
                          style={{ fontSize: "23px" }}
                        />
                      </span>
                      <span>
                        <a
                          href="/aboutus"
                          style={{
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontFamily: "inherit",
                          }}
                        >
                          About Us
                        </a>
                      </span>
                    </p>
                  </p>
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <MdKeyboardDoubleArrowRight
                          style={{ fontSize: "23px" }}
                        />
                      </span>
                      <span>
                        <a
                          href="/services"
                          style={{
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontFamily: "inherit",
                          }}
                        >
                          Services
                        </a>
                      </span>
                    </p>
                  </p>
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <MdKeyboardDoubleArrowRight
                          style={{ fontSize: "23px" }}
                        />
                      </span>
                      <span>
                        <a
                          href="/contactus"
                          style={{
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontFamily: "inherit",
                          }}
                        >
                          Contact Us
                        </a>
                      </span>
                    </p>
                  </p>
                </div>
                <div className="col-md-2">
                  <h5
                    className="text-uppercase mb-4 font-weight-bold"
                    style={{ color: "#FE5E00", fontFamily: "Carnas Light" }}
                  >
                    SOCIAL MEDIAS
                  </h5>
                  <hr
                    style={{
                      border: "1px solid white",
                      color: "white",
                      width: "80%",
                    }}
                  />
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <FaFacebookSquare style={{ fontSize: "23px" }} />
                      </span>
                      <span>
                        <a
                          href=""
                          style={{
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontFamily: "inherit",
                          }}
                        >
                          Facebook
                        </a>
                      </span>
                    </p>
                  </p>
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <FaTwitter style={{ fontSize: "23px" }} />
                      </span>
                      <span>
                        <a
                          href=""
                          style={{
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontFamily: "inherit",
                          }}
                        >
                          Twitter
                        </a>
                      </span>
                    </p>
                  </p>
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <PiInstagramLogoFill style={{ fontSize: "23px" }} />
                      </span>
                      <span>
                        <a
                          href=""
                          style={{
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontFamily: "inherit",
                          }}
                        >
                          Instagram
                        </a>
                      </span>
                    </p>
                  </p>
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <IoLogoLinkedin style={{ fontSize: "23px" }} />
                      </span>
                      <span>
                        <a
                          href=""
                          style={{
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                            fontFamily: "inherit",
                          }}
                        >
                          Linked in
                        </a>
                      </span>
                    </p>
                  </p>
                </div>
                {/* <div className="col-md-4">
                  <h5
                    className="text-uppercase mb-4 font-weight-bold"
                    style={{ color: "#FE5E00" }}
                  >
                    QUICK CONTACT
                  </h5>
                  <hr
                    style={{
                      border: "1px solid white",
                      color: "white",
                      width:"70%"
                    }}
                  />
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-2">
                      <span>
                        <MdLocationPin style={{ fontSize: "23px" }} />
                      </span>
                      <span>123, New Lenox Chicago, IL 60606</span>
                    </p>
                  </p>
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-3">
                      <span>
                        <GrMail />
                      </span>
                      <span>info@hradviser.com</span>
                    </p>
                  </p>
                  <p style={{ color: "whitesmoke" }}>
                    <p className="d-flex gap-3">
                      <span>
                        <BiSolidPhoneCall />
                      </span>
                      <span>+3 833 211 32</span>
                    </p>
                  </p>
                </div> */}
                <div className="col-md-5">
                  <div className="container">
                    <div className="row" style={{ padding: "6px" }}>
                      <div className="col-md-12 text-center">
                        <Form
                          className="form-01"
                          ref={form}
                          onSubmit={sendEmail}
                        >
                          <Form.Control
                            type="text"
                            name="user_name"
                            placeholder="Enter Your Name "
                            required
                          />
                          <br />
                          <Form.Control
                            type="email"
                            name="user_email"
                            placeholder="Enter Your Email ID "
                            required
                          />
                          <br />
                          <Form.Control
                            as="textarea"
                            type="text"
                            name="user_message"
                            placeholder="Message"
                            style={{ height: "100px" }}
                          />
                          <Button
                            type="submit"
                            value="Send"
                            variant="btn-danger mt-1"
                            style={{
                              backgroundColor: "#010161",
                              color: "white",
                              borderRadius: "0px",
                              border: "1px solid white",
                              padding: "20px",
                            }}
                          >
                            Send Request
                          </Button>
                        </Form>
                      </div>
                    </div>

                    {/* <div className="row text-center">
                      <div className="col-md-12">
                        <Button
                          variant="btn-danger"
                          style={{
                            backgroundColor: "#010161",
                            color: "white",
                            border: "1px solid white",
                          }}
                          //   onClick={EnquiryS}
                          // state={{ item: item }}
                        >
                          Send Request
                        </Button>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ color: "whitesmoke" }} />
            <div className="container mb-3">
              <div className="row text-center">
                <div className="col">
                  <p style={{ color: "whitesmoke", fontFamily: "inherit" }}>
                    Company name Site - Trusted by over thousands of HR's for
                    success.
                    <button
                      className="btn btn primary "
                      style={{ color: "blue", fontFamily: "inherit" }}
                    >
                      join us today
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="aefe"
              style={{ display: "flex", justifyContent: "space-between" }}
            ></div>
            <div style={{ backgroundColor: "red" }}></div>
            <div className="container">
              <div className="row">
                <div className="col text-center">
                  <p style={{ color: "whitesmoke", fontFamily: "inherit" }}>
                    Copyright © 2023 Company.com All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
