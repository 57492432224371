import React from "react";
import OwlCarousel from "react-owl-carousel";

const Partners = () => {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 3,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };
  return (
    <div>
      <div
        className="container-fluid mt-5"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        <div className="row pt-3 text-center">
          <h1 style={{fontFamily: "Carnas Light",fontSize:"3rem",color:"#000077"}}>Our Partners</h1>
          <h6 style={{fontFamily: "Carnas Light"}}>We Have Trusted Clients</h6>
        </div>
        <div className="row pt-5 pb-2">
          <OwlCarousel className="owl-theme" loop margin={10} nav {...options}>
            <div class="item">
              <img
                src="client1.png"
                alt=""
                style={{ width: "150px", height: "100px" }}
              />
            </div>
            <div class="item">
              <img
                src="client2.jpg"
                alt=""
                style={{ width: "150px", height: "100px" }}
              />
            </div>
            <div class="item">
              <img
                src="client3.png"
                alt=""
                style={{ width: "150px", height: "100px" }}
              />
            </div>
            <div class="item">
              <img
                src="client4.jpg"
                alt=""
                style={{ width: "150px", height: "100px" }}
              />
            </div>
            <div class="item">
              <img
                src="client5.png"
                alt=""
                style={{ width: "150px", height: "100px" }}
              />
            </div>
            <div class="item">
              <img
                src="client6.png"
                alt=""
                style={{ width: "150px", height: "100px" }}
              />
            </div>
            <div class="item">
              <img
                src="client7.png"
                alt=""
                style={{ width: "150px", height: "100px" }}
              />
            </div>
            <div class="item">
              <img
                src="client8.png"
                alt=""
                style={{ width: "150px", height: "100px" }}
              />
            </div>
            <div class="item">
              <img
                src="client9.png"
                alt=""
                style={{ width: "150px", height: "100px" }}
              />
            </div>
            <div class="item">
              <img
                src="client10.png"
                alt=""
                style={{ width: "150px", height: "100px" }}
              />
            </div>
            <div class="item">
              <img
                src="client11.png"
                alt=""
                style={{ width: "150px", height: "100px" }}
              />
            </div>
            <div class="item">
              <img
                src="client12.png"
                alt=""
                style={{ width: "150px", height: "100px" }}
              />
            </div>
          </OwlCarousel>
          
        </div>
      </div>
    </div>
  );
};

export default Partners;
