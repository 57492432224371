import React, { useRef, useState } from "react";
import { BiSolidPhoneCall } from "react-icons/bi";
import { Tooltip } from "antd";

const PhoneCall = () => {
  const text = (
    <span>
      Chat Now
      <br /> Click here..!
    </span>
  );
  const buttonWidth = 70;

  return (
    <div
      style={{
        marginLeft: buttonWidth,
        whiteSpace: "nowrap",
      }}
    >
      <Tooltip
        placement="leftTop"
        //   title={text}
      >
        <div
          className="scroll_top_button1 active_01 k9"
          style={{ backgroundColor: "#47c758" }}
        >
          <a href="tel:9743403928" target="_blanck">
            <BiSolidPhoneCall style={{ color: "white", fontSize: "29px" }} />
          </a>
        </div>
      </Tooltip>
    </div>
  );
};

export default PhoneCall;
