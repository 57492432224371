import React, { useState } from "react";
import { BsWhatsapp } from "react-icons/bs";
import { Tooltip } from "antd";

const Whatsapp = () => {
  const text = (
    <span>
      Chat Now
      <br /> Click here..!
    </span>
  );
  const buttonWidth = 70;
  return (
    <div
      style={{
        marginLeft: buttonWidth,
        whiteSpace: "nowrap",
      }}
    >
      <Tooltip placement="leftTop" title={text}>
        <div
          className="scroll_top_button active_0 k9"
          style={{ backgroundColor: "#47c758" }}
        >
          <a href="https://web.whatsapp.com/" target="_blanck">
            <BsWhatsapp style={{ color: "white", fontSize: "29px" }} />
          </a>
        </div>
      </Tooltip>
    </div>
  );
};

export default Whatsapp;
