import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { IoIosArrowUp } from "react-icons/io";

const Backtotop = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Show the button when scrolling down
    const handleScroll = () => {
      if (window.pageYOffset > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <button
      className={`back-to-top-button ${isVisible ? "visible" : ""}`}
      onClick={scrollToTop}
      title="Go to top"
    >
     <IoIosArrowUp style={{color:"white"}}/>
    </button>
  );
};

export default Backtotop;
