import React, { useRef } from "react";
import { MdLocationPin } from "react-icons/md";
import { GrMail } from "react-icons/gr";
import { BiSolidPhoneCall } from "react-icons/bi";
import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { PiInstagramLogoFill } from "react-icons/pi";
import { IoLogoLinkedin } from "react-icons/io";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_hxhi9je",
        "template_xax9v3v",
        form.current,
        "MUIWT1wj9Eqv9fJC0"
      )
      .then(
        (result) => {
          // console.log(result.text, "afewaeh");
          window.location.assign("/contactus");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div className="container py-4 ">
        <div className="row gap-3 top">
          <div
            className="col-3 col-sm-3 col-md-3 col-lg-3  contactuu"
            style={{ boxShadow: "10px 10px 32px 0 rgba(19,19,20,0.37)" }}
          >
            <MdLocationPin style={{ fontSize: "50px", color: "#010161" }} />
            <br />
            <h6>
              No.151, 2nd Floor, 6th A Main Road, 7th A” Cross, Near Hoysala
              Circle, Kengeri Satellite Town, Bengaluru - 560060
            </h6>
          </div>
          <div
            className="col-3 col-sm-3 col-md-3 col-lg-3   contactuu"
            style={{ boxShadow: "10px 10px 32px 0 rgba(19,19,20,0.37)" }}
          >
            <GrMail style={{ fontSize: "50px", color: "#010161" }} />
            <br />
            <h6>
              leadhrconsulting
              <br />
              solutions@gmail.com
            </h6>
          </div>
          <div
            className="col-3 col-sm-3 col-md-3 col-lg-3  contactuu"
            style={{ boxShadow: "10px 10px 32px 0 rgba(19,19,20,0.37)" }}
          >
            <BiSolidPhoneCall style={{ fontSize: "50px", color: "#010161" }} />
            <br />
            <h6>
              +91-9743403928, <br />
              +91-7259646275
            </h6>
          </div>
          <div
            className="col-2 col-sm-2 col-md-2 col-lg-2 gap-3 contactuu"
            style={{ padding: "70px" }}
          >
            <FaFacebookSquare style={{ fontSize: "30px", color: "#010161" }} />
            <FaTwitter style={{ fontSize: "30px", color: "#010161" }} />
            <PiInstagramLogoFill
              style={{ fontSize: "30px", color: "#010161" }}
            />
            <IoLogoLinkedin style={{ fontSize: "30px", color: "#010161" }} />
          </div>
        </div>
      </div>
      <div
        className="container"
        style={{ backgroundColor: "#C8D6E5", padding: "20px" }}
      >
        <div className="row">
          <div className="col-md-4">
            <img
              className="contactimg"
              src="homeimage1.jpg"
              alt=""
              style={{
                width: "100%",
                height: "90%",
                padding: "40px",
              }}
            />
          </div>
          <div className="col-md-8">
            <div className="container">
              <div className="row contact1" style={{ padding: "40px" }}>
                <div className="col-md-12">
                  <Form className="form-01" ref={form} onSubmit={sendEmail}>
                    <Form.Control
                      type="text"
                      name="user_name"
                      placeholder="Enter Your Name "
                      required
                    />
                    <br />
                    <Form.Control
                      type="email"
                      name="user_email"
                      placeholder="Enter Your Email ID "
                      required
                    />
                    <br />
                    <Form.Control
                      as="textarea"
                      type="text"
                      name="user_message"
                      placeholder="Message"
                      style={{ height: "100px" }}
                    />
                    <div className="text-center pt-2">
                      <Button
                        type="submit"
                        value="Send"
                        variant="btn-danger"
                        style={{
                          backgroundColor: "#010161",
                          color: "white",
                          borderRadius: "0px",
                          padding: "20px",
                        }}
                      >
                        Send Request
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>

              {/* <div className="row text-center">
                <div className="col-md-3" style={{ marginLeft: "317px" }}>
                  <Button
                    type="submit"
                    value="Send"
                    variant="btn-danger"
                    style={{
                      backgroundColor: "#010161",
                      color: "white",
                      borderRadius: "0px",
                      padding: "20px",
                    }}
                  >
                    Send Request
                  </Button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-2">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62221.01599077507!2d77.4061746604236!3d12.919705302803347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3ed945161c79%3A0x27765dfd47685241!2sKengeri%20Satellite%20Town%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1701082247613!5m2!1sen!2sin"
          style={{ width: "100%" }}
          height="450"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.3527250670204!2d77.53594047392575!3d13.076817712565017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae235f0a8b57a7%3A0xaea5b061a969b5d1!2sParnets%20software!5e0!3m2!1sen!2sin!4v1700633630309!5m2!1sen!2sin"
          style={{ width: "100%" }}
          height="450"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe> */}
      </div>
    </div>
  );
};

export default ContactUs;
