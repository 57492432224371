import React, { useState } from "react";

const VissionMission = () => {
  const [vision, setVision] = useState(true);
  const [Mission, setMission] = useState(false);
  //   const [Adddaboutus, setAdddaboutus] = useState([]);
  //   const getaddaboutus = async () => {
  //     try {
  //       let res = await axios.get(
  //         "http://localhost:9000/api/admin/getallaboutus"
  //       );
  //       if (res.status === 200) {
  //         setAdddaboutus(res.data.getAddaboutus);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   useEffect(() => {
  //     getaddaboutus();
  //   }, []);
  return (
    <div>
      <div className="container-fluid pt-5">
        <div className="row pt-4 ">
          <div className="col-md-2 button">
            <div className="d-flex align-items-end p-2 justify-content-end">
              <div className="">
                <button
                  className="btn-blue"
                  onClick={() => {
                    setVision(true);
                    setMission(false);
                  }}
                >
                  Vision
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-2 button1">
            <div className="d-flex align-items-start p-2 justify-content-start">
              <div className="">
                <button
                  className="btn-blue"
                  onClick={() => {
                    setVision(false);
                    setMission(true);
                  }}
                >
                  Mission
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid mb-3">
          <div className="row">
            {vision ? (
              <>
                <div className="container">
                  <div className="row">
                    <div className="col-md-4 text-end visionimg">
                      <p>
                        <img
                          src="vision.jpg"
                          alt="logo"
                          style={{ width: "72%" }}
                        />
                      </p>
                    </div>
                    <div className="col-md-8 vision" style={{ padding: "30px" }}>
                      <h1
                        style={{
                          fontFamily: "Carnas Light",
                          fontSize: "2.9rem",
                        }}
                      >
                        Our <span style={{ color: "#010161" }}>Vision</span>
                      </h1>
                      <p
                        style={{
                          // color: "#585f63",
                          fontFamily: "inherit",
                          // marginTop: "35px",
                          lineHeight: "25px",
                          fontSize: "17px",
                        }}
                      >
                        "To become preferable HR Consultancy Company in India by
                        serving esteemed customers by delivering high quality HR
                        services".
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {Mission ? (
                  <>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-8 mission" style={{ padding: "45px",paddingLeft:"10rem" }}>
                          <h1
                            style={{
                              fontFamily: "Carnas Light",
                              fontSize: "2.9rem",
                            }}
                          >
                            Our{" "}
                            <span style={{ color: "#010161" }}>Mission</span>
                          </h1>
                          <p
                            style={{
                              // color: "#585f63",
                              fontFamily: "inherit",
                              // marginTop: "35px",
                              lineHeight: "25px",
                              fontSize: "17px",
                            }}
                          >
                            "Focussing on specific customer requirements in all
                            domains of Human Resource Filed by deploying right
                            people at right time and we believe that our people
                            will make the difference to our esteemed clients.".
                          </p>
                        </div>
                        {/* <div className="col-md-8">
                          <h1>
                            Our{" "}
                            <span style={{ color: "#119EE6" }}>Mission</span>
                          </h1>
                          <h5 className="text-justify">
                            Focussing on specific customer requirements in all
                            domains of Human Resource Filed by deploying right
                            people at right time and we believe that our people
                            will make the difference to our esteemed clients.
                          </h5>
                        </div> */}
                        <div className="col-md-4 missionimg">
                          <img
                            src="./mission.png"
                            alt="logo"
                            style={{ width: "72%", height: "90%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VissionMission;
