import React from "react";

const Services = () => {
  return (
    <div>
        <div className="container whole"  style={{ backgroundColor: "#F1F6FB", padding: "50px" }}>
        <div className="row ">
          <div className="col-md-6 p-3">
            <h5 style={{ color: "#010161", fontFamily: "Carnas Light" }}>
              CORE SERVICES
            </h5>
            <br />
            <h3 style={{ fontFamily: "Carnas Light", fontSize: "2.9rem" }}>
              We deliver HR outsorcing services through an integrated technology
            </h3>
          </div>
          <div className="col-md-6 p-3">
            <p
              style={{
                color: "#585f63",
                fontFamily: "inherit",
                marginTop: "45px",
                lineHeight: "25px",
                fontSize: "17px",
              }}
            >
               We offer comprehensive services such as Executive Staffing,
              Contractual/Lease manpower supply, Statutory Compliance, Payroll
              management , Learning and Development, Statutory Audits and
              Liasoning. With Our Company as your strategic HR partner, you can
              focus on such as Learning and Development and also Executive
              Staffing.
            </p>
          </div>
        </div>
      </div>
     

      <div className="container" style={{ backgroundColor: "#C8D6E5" }}>
        <div className="row">
        <div className="col-md-4">
            <img
              src="staff.jpg"
              alt=""
              style={{
                width: "100%",
                height: "90%",
                padding: "40px",
              }}
            />
          </div>
          <div className="col-md-8" style={{ padding: "40px" }}>
            <h5
              style={{
                color: "#010161",
                fontFamily: "Carnas Light",
                fontSize: "2rem",
              }}
            >
              Executive Staffing
            </h5>
            <p
              style={{
                color: "black",
                fontFamily: "inherit",
                // marginTop: "35px",
                lineHeight: "25px",
                fontSize: "17px",
                textAlign: "justify",
              }}
            >
              Our mission at Executive Staffing is to bring the best people
              together.
              <br />
              <br />
              Our purpose is to drive the success of our clients by integrating
              our core values of self-accountability, pride of ownership,
              commitment to quality, empowerment and respect for one another
              into a highly passionate approach in building business
              relationships.
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-4" style={{ backgroundColor: "#C8D6E5" }}>
        <div className="row">
          <div className="col-md-8" style={{ padding: "40px" }}>
            <h5
              style={{
                color: "#010161",
                fontFamily: "Carnas Light",
                fontSize: "2rem",
              }}
            >
              Contractual/Lease manpower supply
            </h5>
            <p
              style={{
                color: "black",
                fontFamily: "inherit",
                // marginTop: "35px",
                lineHeight: "25px",
                fontSize: "17px",
                textAlign: "justify",
              }}
            >
              Contractual or lease manpower supply services provide
              organizations with flexible staffing solutions by outsourcing the
              recruitment, management, and deployment of temporary or contract
              employees.
              <br />
              <br />
              Identifying and attracting qualified candidates to fulfill
              temporary or contract positions within the client organization.
              This involves advertising, screening, and shortlisting
              candidates.Providing administrative support related to HR
              functions, such as record-keeping, reporting, and documentation of
              employment-related activities.
            </p>
          </div>
          <div className="col-md-4">
            <img
              src="learning.jpg"
              alt=""
              style={{
                width: "100%",
                height: "90%",
                padding: "40px",
              }}
            />
          </div>
        </div>
      </div>
      <div className="container mt-4" style={{ backgroundColor: "#C8D6E5" }}>
        <div className="row">
          <div className="col-md-4">
            <img
              src="compilance.png"
              alt=""
              style={{
                width: "100%",
                height: "100%",
                padding: "40px",
              }}
            />
          </div>
          <div className="col-md-8" style={{ padding: "40px" }}>
            <h5
              style={{
                color: "#010161",
                fontFamily: "Carnas Light",
                fontSize: "2rem",
              }}
            >
              Statutory Compliance
            </h5>
            <p
              style={{
                color: "black",
                fontFamily: "inherit",
                // marginTop: "35px",
                lineHeight: "25px",
                fontSize: "17px",
                textAlign: "justify",
              }}
            >
              Ensuring compliance with regulations related to working hours,
              overtime calculations, and rest periods. This includes adherence
              to legal limits on daily and weekly working hours.
              <br />
              <br />
              This helps in avoiding legal risks, penalties, and reputational
              damage associated with non-compliance. These services are
              particularly crucial in industries where the regulatory landscape
              is complex and subject to frequent changes.
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-4" style={{ backgroundColor: "#C8D6E5" }}>
        <div className="row">
          <div className="col-md-8" style={{ padding: "40px" }}>
            <h5
              style={{
                color: "#010161",
                fontFamily: "Carnas Light",
                fontSize: "2rem",
              }}
            >
              Payroll management
            </h5>
            <p
              style={{
                color: "black",
                fontFamily: "inherit",
                // marginTop: "35px",
                lineHeight: "25px",
                fontSize: "17px",
                textAlign: "justify",
              }}
            >
              Outsourcing the process of calculating, disbursing, and managing
              employee salaries, taxes, and other financial transactions related
              to compensation. Accurate calculation of employee salaries based
              on factors such as hours worked, overtime, bonuses, and other
              components of compensation.
              <br />
              <br />
              Calculation and management of various deductions from employees'
              salaries, including taxes, provident fund contributions, insurance
              premiums, and other statutory or voluntary deductions.
            </p>
          </div>
          <div className="col-md-4">
            <img
              src="payroll.png"
              alt=""
              style={{
                width: "100%",
                height: "90%",
                padding: "40px",
              }}
            />
          </div>
        </div>
      </div>
      <div className="container mt-4" style={{ backgroundColor: "#C8D6E5" }}>
        <div className="row">
          <div className="col-md-4">
            <img
              src="learninganddev.jpg"
              alt=""
              style={{
                width: "100%",
                height: "100%",
                padding: "40px",
              }}
            />
          </div>
          <div className="col-md-8" style={{ padding: "40px" }}>
            <h5
              style={{
                color: "#010161",
                fontFamily: "Carnas Light",
                fontSize: "2rem",
              }}
            >
              Learning and Development
            </h5>
            <p
              style={{
                color: "black",
                fontFamily: "inherit",
                // marginTop: "35px",
                lineHeight: "25px",
                fontSize: "17px",
                textAlign: "justify",
              }}
            >
              Aimed to support professional growth, improve job performance, and
              contribute to overall organizational success. Here are the key
              aspects of what Learning and Development services provides.
              <br />
              <br />
              Fostering a culture of continuous learning within the
              organization, encouraging employees to seek out learning
              opportunities and stay updated on industry trends.
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-4" style={{ backgroundColor: "#C8D6E5" }}>
        <div className="row">
          <div className="col-md-8" style={{ padding: "40px" }}>
            <h5
              style={{
                color: "#010161",
                fontFamily: "Carnas Light",
                fontSize: "2rem",
              }}
            >
              Statutory Audits
            </h5>
            <p
              style={{
                color: "black",
                fontFamily: "inherit",
                // marginTop: "35px",
                lineHeight: "25px",
                fontSize: "17px",
                textAlign: "justify",
              }}
            >
              Statutory audits in the context of HR typically focus on ensuring
              compliance with various labor laws, employment regulations, and
              statutory requirements. These audits are conducted to review HR
              practices and processes to confirm that they align with legal
              standards and regulations. Here are some key aspects of what
              statutory audits services provide in HR
              <br />
              <br />
              Ensuring that required reports and filings related to HR
              activities are accurate and submitted within stipulated timelines.
              This may include reports on attendance, wages, and social security
              contributions.
            </p>
          </div>
          <div className="col-md-4">
            <img
              src="statutory.jpg"
              alt=""
              style={{
                width: "100%",
                height: "90%",
                padding: "40px",
              }}
            />
          </div>
        </div>
      </div>
      <div className="container mt-4" style={{ backgroundColor: "#C8D6E5" }}>
        <div className="row">
          <div className="col-md-4">
            <img
              src="liasoning.jpg"
              alt=""
              style={{
                width: "100%",
                height: "100%",
                padding: "40px",
              }}
            />
          </div>
          <div className="col-md-8" style={{ padding: "40px" }}>
            <h5
              style={{
                color: "#010161",
                fontFamily: "Carnas Light",
                fontSize: "2rem",
              }}
            >
              Liasoning
            </h5>
            <p
              style={{
                color: "black",
                fontFamily: "inherit",
                // marginTop: "35px",
                lineHeight: "25px",
                fontSize: "17px",
                textAlign: "justify",
              }}
            >
              Getting all the Licences, Approvals, Permissions, No Objection
              certificates from various Govt. Departments such as Department of
              Labour, Department of Factories and Boilers, Shops and Commercial
              Establishments, Karnataka State Pollution Control Board and other
              State pollution control boards, BBMP approvals, Trade License,
              EPF, ESI, PT, GST etc.,
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
