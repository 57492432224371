import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import { GrMail } from "react-icons/gr";
import { BiSolidPhoneCall } from "react-icons/bi";

const NavBar = () => {
  return (
    <div style={{ position: "sticky", top: "0", left: "0", zIndex: "99999" }}>
      <div className="container-fluid navp" style={{ paddingLeft: "unset" }}>
        <div className="row">
          <div
            className="col-md-12"
            style={{ paddingRight: "unset" }}
          >
            <Navbar
              expand="lg"
              style={{ padding: "unset", background: "white" }}
            >
              <Container fluid>
                <Navbar.Brand href="">
                  <img
                    src="logo.jpg"
                    alt=""
                    style={{
                      width: "109%",
                      height: "7rem",
                      borderRadius: "50%",
                    }}
                  />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav
                    className="ms-auto"
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, #010161 , #00008e)",
                      height: "7.7rem",
                      padding: "33.5px",
                    }}
                  >
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/aboutus">About Us</Nav.Link>
                    <Nav.Link href="/services">Services</Nav.Link>
                    <Nav.Link href="/contactus">Contact Us</Nav.Link>
                    <div style={{ marginTop: "21px" }}>
                      <span>
                        {" "}
                        <GrMail
                          className="navvv"
                          style={{
                            fontSize: "19px",
                            color: "white",
                            marginRight: "5px",
                            marginLeft: "87px",
                          }}
                        />{" "}
                        <span style={{ color: "white" }}>
                          leadhrconsultingsolutions@gmail.com
                        </span>{" "}
                        <span style={{ color: "white" }}>|</span>{" "}
                        <span>
                          <BiSolidPhoneCall
                            style={{
                              fontSize: "19px",
                              color: "white",
                              marginRight: "4px",
                            }}
                          />
                        </span>
                        <span style={{ fontSize: "16px", color: "white" }}>
                          97434&nbsp;03928
                        </span>{" "}
                      </span>
                    </div>
                  </Nav>
                  {/* <Navbar >
                    <Nav className="m-auto" >
                     
                    </Nav>
                  </Navbar> */}
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
          {/* <div
            className="col-md-4"
            style={{ backgroundColor: "#010161", padding: "51px" }}
          >
            <div className="d-flex gap-3">
              <div>
                <span></span>
                <span style={{ fontSize: "16px", color: "white" }}></span>
              </div>

              <div></div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
